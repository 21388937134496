import * as React from "react"
import { useEffect } from "react"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import {
  getNoveltieAction,
  getNoveltiesAction,
} from "../../../redux/noveltiesDucks"

import { connect } from "react-redux"
// import ClientSideOnlyLazy from "../../../components/Novedad/main"
//Components

const ClientSideOnlyLazy = React.lazy(() =>
  import('../../../components/Novedad/main')
)

const IndexPage = props => {
  const { id } = props
  const { slug } = props
  const { dispatch } = props

  const isSSR = typeof window === "undefined"

  return (
    <Layout preloader={true}>
      <Seo title="Novedad" />
      {!isSSR && (
          <React.Suspense fallback={<div />}>
            <ClientSideOnlyLazy id={id}/>
          </React.Suspense>
        )}
    </Layout>
  )
}

export default connect(state => ({}), null)(IndexPage)
